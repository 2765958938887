import React, { useEffect, useMemo, useState } from "react";
import styles from "./style/ranking.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Skeleton } from "@mui/material";
import { useRive } from "@rive-app/react-canvas";

export const RankingPage = ({ page }) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const { RiveComponent } = useRive({
    src: "/images/flower-background.riv",
    autoplay: true,
  });
  const rank = useMemo(
    () =>
      page === "pay"
        ? searchParams.get("transaction_rank")
        : searchParams.get("referral_rank"),
    [page, searchParams],
  );
  const transactionVolume = useMemo(
    () => searchParams.get("transaction_volume"),
    [searchParams],
  );
  const referralCount = useMemo(
    () => searchParams.get("referral_count"),
    [searchParams],
  );

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(2) + "L";
    if (n >= 1e7) return +(n / 1e7).toFixed(2) + "Cr";
  };

  useEffect(() => {
    try {
      const { REACT_APP_BASE_URL } = process.env;
      const URL = `${REACT_APP_BASE_URL}/diwali/leaderboard/${
        page === "pay" ? "transaction" : "referral"
      }`;
      axios.get(URL).then((response) => {
        setData({
          ...response.data.data,
          startDate: new Date(response.data.data.startDate),
          endDate: new Date(response.data.data.endDate),
        });
        setLoading(false);
      });

      if (page === "pay")
        window.webengage?.track("payapp_diwali_webview_paynwin_land", {
          screen_name: "payapp_diwali_webview_paynwin",
        });
      else
        window.webengage?.track("payapp_diwali_webview_refernwin_land", {
          screen_name: "payapp_diwali_webview_refernwin",
        });
    } catch (e) {
      console.error(e);
    }
  }, [page]);

  return (
    <div className="container center">
      <span
        className={`${styles.faq}`}
        onClick={() => {
          window.webengage?.track("payapp_diwali_webview_faq_click", {
            screen_name: "payapp_diwali_webview_page",
          });
          navigate("/faqs");
        }}
      >
        FAQ
      </span>
      <div className={`${styles.mandalaContainer}`}>
        <img src="/images/slice-top.svg" alt="" className={`${styles.mandalaTop}`} />
        <div className={`${styles.riveContainer}`}>
          <RiveComponent style={{ width: "95%", height: "100%" }} />
        </div>
        <div className={`${styles.textContainer} center-absolute`}>
         <img
            src="/images/pice-white-logo.svg"
            alt=""
            className={`${styles.piceLogo} center-inline`}
          />
          <span className={`${styles.heading} center-absolute`}>
            {page === "pay" ? "Pay & Win" : "Refer & Win"}
          </span>
          <div className={`${styles.daysLeft} center-absolute`}>
            <img src="/images/clock.svg" alt="" className={`${styles.clock}`} />
            {loading && <Skeleton animation="pulse" width="5em" />}
            {data?.endDate && data?.startDate && (
              <span>{`${
                data.endDate < new Date()
                  ? 0
                  : Math.ceil(
                      Math.abs(data.endDate - new Date()) /
                        (1000 * 60 * 60 * 24),
                    )
              } Days Left`}</span>
            )}
          </div>
        </div>
        <div className={`${styles.divider} center-inline`}>
          <img width={"60%"} src="/images/divider.svg" alt="" />
        </div>
      </div>

      <span className={`${styles.subheading} center-inline`}>
        {page === "pay"
          ? "Make highest payment from Pice App"
          : "Refer the most businesses to Pice App"}
      </span>
      <div className={`${styles.description}`}>
        Criteria:
        <ul>
          {page === "pay" ? (
            <li>Pay Rs. 5 lakh from Pice App to participate</li>
          ) : (
            <li>Refer more than 5 GST businesses to participate</li>
          )}
          {loading && <Skeleton animation="pulse" width="40ch" />}
          {data?.startDate && data?.endDate && (
            <li>{`Offer period: ${data.startDate.toLocaleString("default", {
              month: "long",
            })} ${data.startDate.getDate()} - ${data.endDate.toLocaleString(
              "default",
              { month: "long" },
            )} ${data.endDate.getDate()}, 2024`}</li>
          )}
        </ul>
      </div>

      <div className={`${styles.leaderboard} center`}>
        <div className={`${styles.leaderboardHeading}`}>
          <span>Leaderboard</span>
          <span>Prizes</span>
        </div>
        <img
          src="/images/leaderboard-divider.svg"
          alt=""
          className={`${styles.leaderboardDivider}`}
        />
        {loading && (
          <>
            <Skeleton animation="pulse" height="8em" />
            <Skeleton animation="pulse" height="8em" />
            <Skeleton animation="pulse" height="8em" />
            <Skeleton animation="pulse" height="8em" />
          </>
        )}
        {data?.leaderboard &&
          data.leaderboard.map((leaderboardEntry, index) => (
            <div key={leaderboardEntry.name + leaderboardEntry.rank}>
              <div className={`${styles.leaderboardEntry}`}>
                <div className={`${styles.nameVolume}`}>
                  <span
                    className={`${styles.name}`}
                  >{`#${leaderboardEntry.rank} ${leaderboardEntry.name}`}</span>
                  <span className={`${styles.volume}`}>
                    {page === "pay"
                      ? `₹${formatCash(leaderboardEntry.amount / 100)}`
                      : `${leaderboardEntry.count} ${
                          leaderboardEntry.count === 1
                            ? "business"
                            : "businesses"
                        }`}
                  </span>
                </div>
                <div className={`${styles.prize}`}>   
                <img 
                    src={leaderboardEntry.rewardImage} 
                    alt={leaderboardEntry.reward} 
                    width={leaderboardEntry.reward !== "Hamper" ? "12px" : "px"} 
                    height={leaderboardEntry.reward !== "Hamper" ? "12px" : "16px"} 
                    style={{ objectFit: 'contain' }} 
                  />
                  <span>{leaderboardEntry.reward}</span>
                </div>
              </div>
              {index < data.leaderboard.length - 1 && (
                <img
                  src="/images/leaderboard-divider.svg"
                  alt=""
                  className={`${styles.leaderboardDivider}`}
                />
              )}
            </div>
          ))}
      </div>
      <div className={`assured-win-container center`}>
        <img src="/images/pink-trophy.svg" alt="" />
        <span>Top 100 users win assured prizes</span>
      </div>
      <img src="/images/bottom.svg" alt="" className={`${styles.mandalaTop}`} />
      {rank !== null &&
        transactionVolume !== null &&
        referralCount !== null && (
          <div className={`${styles.myRankContainer} ios-media`}>
            {rank !== "" && (
              <div className={`${styles.rank}`}>
                <div>
                  <img src="/images/rank-trophy.svg" alt="" />
                </div>
                <span>{`My Rank #${rank}`}</span>
              </div>
            )}
            {rank === "" && (
              <span>
                {page === "pay" ? "Start Transacting" : "Start Referring"}
              </span>
            )}
            <span className={`${styles.volume}`}>
              {page === "pay"
                ? `Paid ₹${formatCash(transactionVolume / 100)}`
                : `${referralCount} Referrals`}
            </span>
          </div>
        )}
    </div>
  );
};
