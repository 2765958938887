import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Skeleton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

export const FAQPage = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const { REACT_APP_BASE_URL } = process.env;
      const URL = `${REACT_APP_BASE_URL}/diwali/faq`;
      axios.get(URL).then((response) => {
        setData(response.data.data);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div className="container center">
      <Typography fontSize={"1.6rem"} margin={"2em"}>
        FAQ's
      </Typography>
      <Box width="90%" margin="0 auto" marginBottom="5em">
        {loading && (
          <>
            <Skeleton animation="pulse" height="8em" />
            <Skeleton animation="pulse" height="8em" />
            <Skeleton animation="pulse" height="8em" />
            <Skeleton animation="pulse" height="8em" />
          </>
        )}
        {data?.faqs &&
          data.faqs.map((faq, index) => (
            <div key={faq.question}>
              <Accordion
                sx={{
                  backgroundColor: "#3C126F",
                  color: "#f7f7fc",
                  fontSize: "1.4rem",
                  boxShadow: "none",
                  margin: "0.5em 0",
                  "&:before": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      style={{ color: "#f7f7fc", fontSize: "2.5rem" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <span>{faq.question}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{faq.answer}</p>
                </AccordionDetails>
              </Accordion>
              {index < data.faqs.length - 1  && (
                <Divider variant="middle" sx={{ backgroundColor: "#E4E4E4", }} />
              )}
            </div>
          ))}
      </Box>
    </div>
  );
};
