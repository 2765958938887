import React, { useEffect, useState } from "react";
import styles from "./style/landing.module.css";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Popup } from "./popup";
import { useRive } from "@rive-app/react-canvas";
import axios from "axios";
import { Modal } from "@mui/material";

export const LandingPage = () => {
  const { search } = useLocation();
  const [modalType, setModalType] = useState(null);
  const [data, setData] = useState();
  const [searchParams] = useSearchParams();
  const { RiveComponent } = useRive({
    src: "/images/flower-background.riv",
    autoplay: true,
  });
  useEffect(() => {
    try {
      const { REACT_APP_BASE_URL } = process.env;
      const URL = `${REACT_APP_BASE_URL}/diwali/home`;

      axios.get(URL).then((response) => {
          setData({
            ...response.data.data,
            startDate: new Date(response.data.data.startDate),
            endDate: new Date(response.data.data.endDate),
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      const userId = searchParams.get("user_id");
      if (userId) {
        window.webengage?.user?.login(userId);
      }

      window.webengage?.track("payapp_diwali_webview_page_land", {
        screen_name: "payapp_diwali_webview_page",
      });
    } catch (e) {
      console.error(e);
    }
  }, [searchParams]);

  return (
    <div
      className={`container center`}
      style={{ overflow: modalType ? "hidden" : "auto" }}
    >
      <img
        src="/images/slice-top.svg"
        alt=""
        className={`${styles.homeTopMandala} center-inline`}
      />
      <div className={`${styles.riveContainer}`}>
        <RiveComponent style={{ width: '100%', height: '100%' }} /> 
      </div>

      <h1 className={styles.offerHeading}>
         <img
            src="/images/pice-white-logo.svg"
            alt=""
            className={`${styles.piceLogo} center-inline`}
          />
        <div className={`${styles.offerHeadingLineOne} center-inline`}>
          <img
            src="/images/diya-left.svg"
            alt=""
            className={`${styles.diya}`}
          />
          <div>Diwali</div>
          <img
            src="/images/diya-right.svg"
            alt=""
            className={`${styles.diya}`}
          />
        </div>

        <div className={`${styles.offerHeadingLineTwo} center-inline`}>
          Vyapaar Utsav
        </div>

        <img
          src="/images/2024.svg"
          className={`${styles.offerSubheading} center-inline`}
          alt=""
        />
      </h1>
      <div></div>
      {/* <img src="/images/cracker1.svg" className={`${styles.cracker1}`} alt="" />
      <img src="/images/cracker2.svg" className={`${styles.cracker2}`} alt="" /> */}
      <div
        className={`${styles.dubaiContainer} center`}
        onClick={() => setModalType("dubai")}
      >
        <img
          src="/images/dubai.png"
          width="380"
          height="170"
          className={`${styles.dubaiImage}`}
          alt=""
        />
        <div className={`${styles.dubaiText}`}>
          <div className={`${styles.dubaiLineOneWrapper}`}>
            <div className={`${styles.dubaiLineOne}`}>
              <span>Win a</span>
              <img src="/images/white-line.svg" alt="" />
            </div>
            <div className={`${styles.phoneLineOne}`}>#1</div>
          </div>
          <span className={`${styles.dubaiLineTwo}`}>Dubai Trip</span>
        </div>
      </div>
      <div className={`${styles.phoneTvContainer} center`}>
        <div
          className={`${styles.phoneContainer}`}
          onClick={() => setModalType("iphone16")}
          style={{
            background: "url(/images/iphone16.png)",
            backgroundSize: "cover",
            backgroundPosition: "0% 30%"
          }}
        >
          <span className={`${styles.phoneLineOne}`}>#2</span>
          <span className={`${styles.phoneLineTwo}`}>iPhone 16 Pro</span>
        </div>
        <div
          className={`${styles.phoneContainer}`}
          onClick={() => setModalType("iphone15")}
          style={{
            background: "url(/images/iphone15.png)",
            backgroundSize: "cover",
            backgroundPosition: "0% 30%"
          }}
        >
          <span className={`${styles.phoneLineOne}`}>#3</span>
          <span className={`${styles.phoneLineTwo}`}>iPhone 15</span>
        </div>
      </div>
      <div className={`assured-win-container__landing center`}>
        <img src="/images/golden_trophy.svg" alt="" />
        <span>Guaranteed prizes for Top 100 users </span>
      </div>
      {/* <img src="/images/cracker3.svg" className={`${styles.cracker3}`} alt="" />
      <img src="/images/cracker4.svg" className={`${styles.cracker4}`} alt="" /> */}
      <div className={`${styles.participateContainer} center`}>
        <div className={`${styles.participateHeading}`}>
          <img src="/images/noto_trophy.svg" alt="" />
          <span>Participate Now</span>
        </div>
        <div className={`${styles.buttonContainer}`}>
          <Link to={`/refer${search}`}>
            <button
              className={`center-inline`}
              onClick={() => {
                window.webengage?.track(
                  "payapp_diwali_webview_refernwin_click",
                  {
                    screen_name: "payapp_diwali_webview_page",
                  },
                );
              }}
            >
              <div className={`${styles.iconGroup}`}>
                <div className={`${styles.iconPadding}`}>
                  <img src="/images/refer.svg" alt="" />
                </div>
                <span>Refer & Win</span>
              </div>
              <img src="/images/right-arrow.svg" alt="" />
            </button>
          </Link>
          <Link to={`/pay${search}`}>
            <button
              className={`center-inline`}
              onClick={() => {
                window.webengage?.track("payapp_diwali_webview_paynwin_click", {
                  screen_name: "payapp_diwali_webview_page",
                });
              }}
            >
              <div className={`${styles.iconGroup}`}>
                <div className={`${styles.iconPadding}`}>
                  <img src="/images/pay-bordered.svg" alt="" />
                </div>
                <span>Pay & Win</span>
              </div>
              <img src="/images/right-arrow.svg" alt="" />
            </button>
          </Link>
        </div>
      </div>
      {data?.startDate && data?.endDate && (
            <div className={styles.dateDuration}>{`${data.startDate.toLocaleString("default", {
            month: "long",
            })} ${data.startDate.getDate()} - ${data.endDate.toLocaleString(
          "default",
          { month: "long" },
        )} ${data.endDate.getDate()}  2024`}</div>
      )}
      <img
        className={`${styles.homeBottomMandala} center-inline`}
        src="/images/bottom.svg"
        alt=""
      />
     
       <Modal open={modalType} >
        <Popup modalType={modalType} handleClose={() => setModalType(null)} />
      </Modal>
     
    </div>
  );
};
