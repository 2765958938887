import { Box, Button, Typography } from "@mui/material";
import React, { useMemo } from "react";

export const Popup = ({ modalType, handleClose }) => {
  const title = useMemo(() => {
    if (modalType === "dubai") return "Trip to Dubai";
    if (modalType === "iphone15") return "Win iPhone 15";
    if (modalType === "iphone16") return "Win iPhone 16 Pro";
  }, [modalType]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        top: "0",
        display: modalType ? "block" : "none",
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
      onClick={handleClose}
    >
      <div
        className="center-absolute"
        style={{
          backgroundColor: "#F6F6F6",
          color: "#0A0A0A",
          position: "absolute",
          width: "90%",
          top: "25%",
          margin: "0 auto",
          borderRadius: "10px",
          padding: "3.2em 2.4em",
          maxWidth: 370
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography
          component="h1"
          sx={{
            fontFamily: "Poppins",
            fontSize: "1.8rem",
            fontWeight: 600,
            marginBottom: "0.8em",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: "1.2rem", color: "#767676", marginBottom: "2.4em" }}
        >
          {modalType === "dubai" && (
            <>
              Make the most amount of transaction or referrals and win an all
              expenses paid trip to Dubai.
              <ul style={{ marginLeft: "1em" }}>
                <li>Trip for 2 people</li>
                <li>3 days & 2 nights</li>
              </ul>
            </>
          )}
          {modalType === "iphone15" && (
            <>
              <div>
                Customer who makes the third highest payment/referral gets the
                brand new 2024 Apple iPhone 15
              </div>
              {/* <div>Stand out in your group with the latest shiny iPhone 15</div> */}
            </>
          )}
          {modalType === "iphone16" && (
            <>
              <div>
                Customer who makes the second highest payment/referral gets the
                brand new 2024 Apple iPhone 16 Pro.
              </div>
              <br/>
              <div>
                Skip the waiting line for your latest shiny iPhone 16 Pro.
              </div>
            </>
          )}
        </Typography>
        <Button
          onClick={handleClose}
          variant="contained"
          style={{
            fontSize: "1.3rem",
            fontFamily: "Rubik",
            borderRadius: "8px",
            backgroundColor: "#431a74",
            width: "90%",
            height: "3em",
            display: "block",
            margin: "0 auto",
          }}
        >
          Got it
        </Button>
      </div>
    </Box>
  );
};
